import * as React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import {
  PageableTable,
  PageableTableRow,
  PopperDropdownMenu,
  SnackbarContext,
} from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { Cancel, Save, Search } from '@mui/icons-material';
import { Tag, TagsResponse } from 'lib/Model/Tag';
import { createTag, deleteTag, fetchTags, updateTag } from 'lib/Service/Tags';
import { useDebounce } from 'hooks';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { AxiosError } from 'axios';

export const TagsTable: React.FC = () => {
  const theme = useTheme();
  const snackbar = React.useContext(SnackbarContext);

  const [textSearch, setTextSearch] = React.useState('');
  const [selectedTag, setSelectedTag] = React.useState<Tag | undefined>();
  const [toDelete, setToDelete] = React.useState<Tag | undefined>();

  const debouncedTextSearch = useDebounce(textSearch, 500);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [queryResponse, setQueryResponse] = React.useState<
    TagsResponse | undefined
  >();

  const [addTagOpen, setAddTagOpen] = React.useState(false);
  const [newTagName, setNewTagName] = React.useState('');
  const [saveTagLoading, setSaveTagLoading] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchTags(debouncedTextSearch, pageNumber + 1, pageSize)
      .then((d) => {
        setLoading(false);
        setQueryResponse(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setLoading, setQueryResponse, pageNumber, pageSize, debouncedTextSearch]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = React.useMemo(() => {
    return [
      {
        key: 'tag',
        label: 'Tag',
        sortable: false,
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        props: {
          style: {
            width: 50,
          },
        },
      },
    ] as PageableTableHeader[];
  }, []);

  const [rows, setRows] = React.useState<PageableTableRow[]>([]);

  React.useEffect(
    () =>
      setRows(
        queryResponse?.data?.map((u) => {
          const isSelected = selectedTag && selectedTag.id === u.id;
          return {
            key: `tag_${u.id}`,
            cells: [
              {
                key: 'tag',
                display: (
                  <React.Fragment>
                    {isSelected ? (
                      <TextField
                        autoFocus
                        fullWidth
                        value={selectedTag!.name}
                        onChange={(e) => {
                          if (!e.target) {
                            return;
                          }
                          setSelectedTag(
                            (t) =>
                              t && {
                                ...t,
                                name: e.target.value,
                              },
                          );
                        }}
                      />
                    ) : (
                      <Typography variant="body1">{u.name}</Typography>
                    )}
                  </React.Fragment>
                ),
              },
              {
                key: 'actions',
                props: {
                  style: {
                    textAlign: 'right',
                  },
                },
                display: !isSelected ? (
                  <PopperDropdownMenu
                    menuItems={[
                      {
                        label: 'Edit',
                        onClick: () => {
                          setSelectedTag(u);
                        },
                      },
                      {
                        label: 'Delete',
                        onClick: () => {
                          setToDelete(u);
                        },
                      },
                    ]}
                  />
                ) : (
                  <React.Fragment>
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={!selectedTag!.name.trim().length}
                      onClick={async () => {
                        if (!selectedTag) {
                          return;
                        }
                        setSaveTagLoading(true);
                        await updateTag(selectedTag)
                          .catch((error) => {
                            if (
                              error instanceof AxiosError &&
                              error.response?.data.message
                            ) {
                              snackbar.error(error.response.data.message);
                              return;
                            }
                            snackbar.error('Unknown error while saving');
                          })
                          .then(() => {
                            const newData = queryResponse?.data?.slice();
                            if (!newData) {
                              return;
                            }

                            const modifiedTag = newData.find(
                              (t) => t.id === selectedTag.id,
                            );
                            if (!modifiedTag) {
                              return;
                            }
                            modifiedTag.name = selectedTag.name;
                            setQueryResponse(
                              (qr) =>
                                qr && {
                                  ...qr,
                                  data: newData,
                                },
                            );
                            setSelectedTag(undefined);
                          })
                          .finally(() => {
                            setSaveTagLoading(false);
                          });
                      }}
                    >
                      <Save fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={() => {
                        setSelectedTag(undefined);
                      }}
                    >
                      <Cancel fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                ),
              },
            ],
          } as PageableTableRow;
        }) ?? [],
      ),
    [queryResponse, setRows, theme, selectedTag, snackbar],
  );

  return (
    <Card>
      <CardHeader
        title="Tags"
        action={
          <Button
            color="primary"
            onClick={() => {
              setSelectedTag(undefined);
              setAddTagOpen(true);
              setNewTagName('');
            }}
          >
            Add tag
          </Button>
        }
      />
      <Toolbar style={{ marginBottom: theme.spacing(1) }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type here to find tags.."
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value ?? '')}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>

      {loading ? (
        <Skeleton variant="rectangular" height={150} />
      ) : (
        <PageableTable
          columns={columns}
          loading={loading}
          rows={rows}
          pageNumber={pageNumber}
          rowCount={queryResponse?.meta?.total ?? rows.length}
          onChangePageSize={setPageSize}
          onChangePage={setPageNumber}
        />
      )}

      {addTagOpen && (
        <Dialog open={true} fullWidth maxWidth="sm">
          <DialogTitle>Create a new tag</DialogTitle>
          <form
            onSubmit={async (e) => {
              e.stopPropagation();
              e.preventDefault();

              setSaveTagLoading(true);
              await createTag({
                id: -1,
                name: newTagName.trim(),
              })
                .catch((error) => {
                  if (
                    error instanceof AxiosError &&
                    error.response?.data.message
                  ) {
                    snackbar.error(error.response.data.message);
                    return;
                  }
                  snackbar.error('Unknown error while saving');
                })
                .then(() => {
                  snackbar.success('Tag created successfully');
                  setNewTagName('');
                  setAddTagOpen(false);
                })
                .finally(() => {
                  setSaveTagLoading(false);
                });

              return false;
            }}
          >
            <DialogContent>
              <TextField
                label="Tag name"
                required
                value={newTagName}
                onChange={(e) => {
                  if (!e.target) {
                    return;
                  }
                  setNewTagName(e.target.value);
                }}
                autoFocus
                fullWidth
                placeholder="Tag name"
              />
            </DialogContent>
            <DialogActions>
              <Button
                type="button"
                color="inherit"
                onClick={() => {
                  setAddTagOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={!Boolean(newTagName) || saveTagLoading}
              >
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}

      {toDelete && (
        <ConfirmDialog
          dialogProps={{
            open: true,
          }}
          typePrompt={`${toDelete?.name ?? 'Delete'}`}
          title="Are you sure you want to proceed?"
          yesText="Delete"
          noText="Cancel"
          onCancel={() => setToDelete(undefined)}
          onConfirm={async () => {
            try {
              await deleteTag(toDelete);
              snackbar.success('Tag deleted');
              fetchData();
            } catch (error) {
              if (error instanceof AxiosError && error.response?.data.message) {
                snackbar.error(error.response.data.message);
                return;
              }
              snackbar.error('Unknown error while saving');
            } finally {
              setToDelete(undefined);
            }
          }}
        />
      )}
    </Card>
  );
};
